<template>
  <div class="h-100">
    <signup-form></signup-form>
  </div>
</template>

<script>
import SignupForm from "../components/SignupForm.vue";
export default {
  components: { SignupForm }
};
</script>

<style>
</style>